import styled from "styled-components";
import { TitleLeft } from "./sharedComponents";
import { YoutubeEmbed } from "./components/embedYoutube";
const Container = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  z-index: 5;

  i {
    font-weight: 150;
  }
  ${TitleLeft} {
    margin-bottom: 15px;
  }
`;

export function TeamBuilding() {
  return (
    <Container>
      <TitleLeft>
        <i>TEAM BUILDING DAY</i>
        <br />
        <b> guarda la prima puntata</b>
      </TitleLeft>
      <YoutubeEmbed embedId="4bl7xDzHE3w"></YoutubeEmbed>
    </Container>
  );
}
